import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import SnackbarProvider from "react-simple-snackbar"

import {
  HeaderCentered,
  HeaderCenteredIntroText,
  ComplaintsForm,
  Layout,
  Seo,
} from "../components/Shared"
import { CoverYourHome } from "../components/Sections"

const Complaints = ({ data }) => {
  return (
    <SnackbarProvider>
      <Layout>
        <Seo
          url={data.site && data.site.siteMetadata.url + "/complaints"}
          title={data.headerAndSeo.seo && data.headerAndSeo.seo.title}
          description={data.headerAndSeo.seo.metaDescription.metaDescription}
          image={
            data.headerAndSeo.seo &&
            data.headerAndSeo.seo.image &&
            data.headerAndSeo.seo.image.file &&
            data.headerAndSeo.seo.image.file.url
          }
        />
        <HeaderCentered
          heading={data.headerAndSeo.headerTitle}
          breakEarly={true}
        />
        <HeaderCenteredIntroText breakEarly={true}>
          <ReactMarkdown
            source={data.headerAndSeo.headerParagraph.headerParagraph}
          />
        </HeaderCenteredIntroText>
        <main>
          <section className="wrapper-width wrapper-height complaints">
            <ComplaintsForm />
          </section>
          <CoverYourHome backgroundColour="grey-lightest" />
        </main>
      </Layout>
    </SnackbarProvider>
  )
}

export default Complaints

export const pageQuery = graphql`
  query ComplaintsContent {
    site {
      siteMetadata {
        url
      }
    }
    headerAndSeo: contentfulPages(page: { eq: "Complaints" }) {
      contentful_id
      page
      seo {
        image {
          file {
            url
          }
        }
        metaDescription {
          metaDescription
        }
        title
      }
      headerTitle
      headerParagraph {
        headerParagraph
      }
    }
  }
`
